/**
 *
 * Payments
 * Payments information
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Consts and Libs
import {SelectList} from '../../constants';
// Components
// Font-Awesome Icons
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faArrowCircleRight} from '@fortawesome/free-solid-svg-icons';
// Form
import * as Yup from 'yup';
import {Formik} from 'formik';
import {Form, Input, Select, SubmitBtn} from 'react-formik-ui';


/* Component ==================================================================== */
class PaymentCredentialsForm extends Component {
  static componentName = 'PaymentCredentialsForm';

  static propTypes = {
    action: PropTypes.func.isRequired,
    initialValues: PropTypes.object.isRequired,
  };


  render = () => {

    const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;
    const phoneErrorMsg = 'Please enter a valid contact number';
    const formValidation = Yup.object().shape({
      email: Yup.string().email().required('Please enter a valid e-mail address.'),
      phone: Yup.string().matches(phoneRegExp, phoneErrorMsg).min(8, 'to short').max(10, `${phoneErrorMsg} without appending 0`).required(phoneErrorMsg),
      phone_code: Yup.string().required('Please select your country prefix.'),
    });
    const {initialValues} = this.props;
    return (
      <div className="payment-contact-data-wrapper">
        <Formik
          onSubmit={(values) => this.props.action ? this.props.action(values) : null}
          validationSchema={formValidation}
          initialValues={initialValues}>
          {() => (
            <Form>
              <div className={'row'}>
                <div className="col-sm-12">
                  <div className="form-row small">
                    <div className="col-12 form-group">
                      <Input
                        name='email'
                        type={'email'}
                        label={'E-mail'}
                        className={'form-control form-control-sm'}
                      />
                    </div>
                    <div className="col-12">
                      <div className={'row'}>
                        <div className={'col-4 pr-0 form-group'}>
                          <Select
                            className={'form-control form-control-sm rounded-right-0'}
                            name='phone_code'
                            label={'Country'}
                            placeholder='Select an Option'
                            options={SelectList.phoneCountryCode()}
                          />
                        </div>
                        <div className={'col-8 pl-0 form-group'}>
                          <Input
                            type={'tel'}
                            name='phone'
                            label={'Phone'}
                            className={'form-control form-control-sm rounded-left-0'}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="col-12">
                      <SubmitBtn className={'btn btn-success btn-block my-2'}>
                        Next <FontAwesomeIcon icon={faArrowCircleRight} className={'ml-2'}/>
                      </SubmitBtn>
                    </div>
                  </div>
                </div>
              </div>
            </Form>
          )}
        </Formik>
      </div>
    );
  };
}

export default PaymentCredentialsForm;