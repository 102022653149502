/**
 *
 * Payments
 * Payments information
 */
import _ from 'lodash';
import PropTypes from 'prop-types';
import React, {Component} from 'react';
import * as Sentry from '@sentry/react';
// Consts and Libs
import AppAPI from '../../lib/api';
import AppUtil from '../../lib/util';
import {Constants} from '../../constants';
// Components
import Loading from '../../components/general/Loading';
import PaymentCredentialsForm from './PaymentCredentialsForm';
import PaymentMessage from '../../components/payments/PaymentMessage';
import TimeCounterView from '../../components/general/TimeCounterView';
// Font-Awesome Icons
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {
  faCheckCircle,
  faCircle,
  faExclamationCircle,
  faHourglassEnd,
  faHourglassHalf, faLongArrowAltRight,
  faPencilAlt
} from '@fortawesome/free-solid-svg-icons';
import Alerts from '../../components/ui/Alerts';


/* Component ==================================================================== */
class PaymentGateways extends Component {
  static componentName = 'Payments';

  static propTypes = {
    payment: PropTypes.object.isRequired,
    setPayment: PropTypes.func.isRequired,
    checkPaymentStatus: PropTypes.func.isRequired,
  };

  checkPaymentStatus = (type='status') => {
    this.setState({loading: true});
    let AppApi = this.props.checkPaymentStatus(this.props.payment.hash);
    if (type === 'payment') {
      AppApi=this.props.setPayment(this.props.payment.hash);
    }

    AppApi.then(() => this.setState({loading: false}))
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, resultMsg: {error}});
      });
  };

  razorPayCapture = (response) => {
    const {userData} = this.state;

    if (response && response.razorpay_payment_id) {
      this.setState({loading: true});
      AppAPI.pgateway.post('return/razorpay/', {
        'gateway': 'razorpay',
        'razorpay_payment_id': response.razorpay_payment_id,
        'invoice_no': this.props.payment.invoice_no,
        'email': !_.isEmpty(userData) ? userData.email : '',
        'phone': !_.isEmpty(userData) ? userData.phone_code + userData.phone : '',
      })
        .then((res) => {
          this.props.setPayment(res);
          this.setState({loading: false});
        })
        .catch((err) => {
          const error = AppAPI.handleError(err);
          this.setState({loading: false, resultMsg: {error}});
        });
    }
  };

  razorPayProcess = (razorpay) => {
    const {payment} = this.props;
    const {property} = payment;
    const {userData} = this.state;
    let options = {
      'key': razorpay.key,
      'amount': razorpay.amount,
      'name': property.name,
      'currency': razorpay.currency,
      'description': razorpay.description,
      'handler': (response) => this.razorPayCapture(response),
      'notes': razorpay.notes,
      'image': (!_.isEmpty(property.logo) && property.logo.logo) ? property.logo.logo : null,
      'prefill': {
        'email': !_.isEmpty(userData) ? userData.email : '',
        'contact': !_.isEmpty(userData) ? userData.phone_code + userData.phone : '',
      }
    };
    if (razorpay.account_id) {
      options['account_id'] = razorpay.account_id;
    }
    let rzp = new window.Razorpay(options);
    rzp.open();
  };

  pineLabsCapture = () => {
    this.setState({loading: false});
  }

  pineLabsProcess = () => {
    const {userData} = this.state;
    this.setState({loading: true});
    AppAPI.pgateway.post(`pgateway/${this.props.payment.hash}/initiate-async/`, {
      'gateway': 'pinelabs',
      'email': !_.isEmpty(userData) ? userData.email : '',
      'phone': !_.isEmpty(userData) ? userData.phone_code + userData.phone : '',
    })
      .then((res) => {
        if (res.token) {
          if (res.method === 'redirect') {
            window.location.replace(res.redirect_url);
          } else{
            const options = {
              theme: 'default', // "default" or "black"
              orderToken: res.token,
              channelId: 'WEB', // "APP" or "WEB"
              paymentMode: res.payment_medhods || 'ALL',
              successHandler: (response)=>this.pineLabsCapture(response),
              failedHandler: (response)=>this.pineLabsCapture(response),
            };
            // eslint-disable-next-line
            const plural = new Plural(options);
            plural.open(options);
          }
        } else {
          const error = AppAPI.handleError(res);
          this.setState({loading: false, resultMsg: {error}});
        }
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({loading: false, resultMsg: {error}});
      });
  };

  setContact = (values) => {
    let selectGateway;
    const {payment} = this.props;
    if (Object.keys(payment.gateway).length === 1) {
      selectGateway = Object.keys(payment.gateway)[0];
    }
    Sentry.setUser({email: values.email, phone: `${values.phone_code}-${values.phone}`});
    this.setState({
      selectGateway:selectGateway,
      userData: {
        'email': values.email,
        'phone': values.phone,
        'phone_code': values.phone_code,
      },
      viewSection: Constants.VIEW_SECTION.GATEWAY_LIST
    });
  };

  submitButton = (gateway, gatewayParams) => {
    const {userData} = this.state;
    const email = !_.isEmpty(userData) ? userData.email : '',
      phone = !_.isEmpty(userData) ? userData.phone_code + userData.phone : '';
    switch (gateway) {
    case 'razorpay':
      return(
        <button className={'btn btn-success btn-large btn-block'} onClick={() => this.razorPayProcess(gatewayParams)}>
          {gatewayParams.buttontext}
        </button>
      );
    case 'pinelabs':
      return(
        <button className={'btn btn-success btn-large btn-block'} onClick={() => this.pineLabsProcess(gatewayParams)}>
          {gatewayParams.buttontext}
        </button>
      );
    case 'paytm':
      return(
        <form action={gatewayParams.action_url} method={'POST'}>
          <input type="hidden" name={'gateway'} value={'paytm'}/>
          <input type="hidden" name={'phone'} value={phone}/>
          <input type="hidden" name={'email'} value={email}/>
          <button className={'btn btn-success btn-large btn-block'}>
            {gatewayParams.button_text}
          </button>
        </form>
      );
    case 'cc_avenue':
      return (
        <form action={gatewayParams.action_url} method={'POST'}>
          <input type="hidden" name={'gateway'} value={'cc_avenue'}/>
          <input type="hidden" name={'phone'} value={phone}/>
          <input type="hidden" name={'email'} value={email}/>
          <button className={'btn btn-success btn-large btn-block'}>
            {gatewayParams.button_text}
          </button>
        </form>
      );
    default:
      return (
        <button className={'btn btn-success btn-large btn-block'} disabled={true}>
          Pay Securely Now
        </button>
      );
    }
  };

  render = () => {

    const {loading, selectGateway, userData, viewSection, resultMsg} = this.state;
    const {payment} = this.props;

    const {property} = payment;

    if (loading) return <Loading heightMatch={false}/>;

    let paymentMessageTitle = 'Oops',
      paymentMessageDesc = 'Payments cannot be processed at the moment.',
      paymentMessageIcon = faExclamationCircle, paymentMessageDescSub, paymentMessageClass;

    switch (payment.payment_status) {
    case Constants.PAYMENT_STATUS.PAID:
      paymentMessageTitle = 'Payment Completed';
      paymentMessageIcon = faCheckCircle;
      paymentMessageDesc = `You have successfully paid ${payment.currency} ${payment.total} on ${AppUtil.formatDateTime(payment.payment_time)}`;
      paymentMessageClass = 'alert-success';
      paymentMessageDescSub = `Payment reference #${payment.gateway_unique_id}`;
      break;
    case Constants.PAYMENT_STATUS.PENDING:
      paymentMessageTitle = 'Pending Update';
      paymentMessageIcon = faHourglassHalf;
      paymentMessageDesc = 'We are awaiting for payment status from your bank.';
      paymentMessageDescSub = 'Order status will stay as not paid until we get a response from the payment processor';
      paymentMessageClass = 'alert-warning';
      break;
    case Constants.PAYMENT_STATUS.WAITING:
      paymentMessageTitle = 'Awaiting Update';
      paymentMessageIcon = faHourglassEnd;
      paymentMessageDesc = 'We are awaiting for payment status from your our payment processor.';
      paymentMessageDescSub = 'Order status will stay as not paid until we get a response from the payment processor';
      paymentMessageClass = 'alert-warning';
      break;
    case Constants.PAYMENT_STATUS.CANCELLED:
      paymentMessageTitle = 'Payment Cancelled';
      paymentMessageIcon = faExclamationCircle;
      paymentMessageDesc = 'The payment request is cancelled/voided by the property, please contact property support for further information';
      paymentMessageClass = 'alert-info';
      paymentMessageDescSub = `Payment reference #${payment.ref_no}`;
      break;
    default:
      break;
    }

    if (!_.isEmpty(payment.pgateway_lock)) {
      paymentMessageTitle = 'Session in Progress';
      paymentMessageIcon = faHourglassEnd;
      paymentMessageDesc = 'A payment session is already in progress. We are awaiting response from our gateway partner.';
      paymentMessageDescSub = 'If you were disconnected in middle of transaction please wait for a few minutes to retry.';
      paymentMessageClass = 'alert-warning';
    }

    const initialValues = {
      'email': !_.isEmpty(userData) ? userData.email : '',
      'phone': !_.isEmpty(userData) ? userData.phone : '',
      'phone_code': !_.isEmpty(property) ? property.phone_number_code : (!_.isEmpty(userData) ? userData.phone_code : '')
    };

    return (
      <div className="payment-gateways">
        <Alerts
          status={resultMsg.status}
          success={resultMsg.success}
          error={resultMsg.error}
        />
        {(payment.allow_payment && !_.isEmpty(payment.gateway)) ?
          <div>
            <div className={'border p-3 rounded mb-2'}>
              <React.Fragment>
                {viewSection === Constants.VIEW_SECTION.CONTACT &&
                  <React.Fragment>
                    {payment.payment_status === Constants.PAYMENT_STATUS.FAILED &&
                      <PaymentMessage
                        description={'Previous payment attempt failed, please try again.'}
                        messageClass={'alert-danger'}
                      />
                    }
                    <PaymentCredentialsForm initialValues={initialValues} action={this.setContact}/>
                  </React.Fragment>
                }
                {viewSection === Constants.VIEW_SECTION.GATEWAY_LIST &&
                <React.Fragment>

                  {!_.isEmpty(userData) &&
                  <div>
                    <p className="text-uppercase small text-secondary pb-0 mb-1">Contact Information</p>
                    <div className={'row'}>
                      <div className={'col-10'}>
                        <p className={'small mb-0'}>Email : <strong>{userData.email}</strong></p>
                        <p className={'small mb-0'}>Phone : <strong>{`${userData.phone_code}-${userData.phone}`}</strong></p>
                      </div>
                      <div className={'col-2 text-right align-self-center'}>
                        <button
                          className={'btn btn-link'}
                          onClick={() => this.setState({viewSection: Constants.VIEW_SECTION.CONTACT})}
                        >
                          <FontAwesomeIcon icon={faPencilAlt}/>
                        </button>
                      </div>
                    </div>
                    <hr/>
                  </div>
                  }
                  {(Object.keys(payment.gateway).length > 1) &&
                  <React.Fragment>
                    <p className="text-uppercase small text-secondary pb-0 mb-1">Gateway</p>
                    <ul className={'list-inline'}>
                      {Object.keys(payment.gateway).map((data, key) => (
                        <li key={key} className={'list-inline-item flex-wrap'}>
                          <button
                            className={`btn my-1 btn-sm no-white-wrap btn-outline-${(selectGateway === data ? 'success' : 'secondary')}`}
                            onClick={() => this.setState({selectGateway: data})}>
                            <FontAwesomeIcon
                              icon={(selectGateway === data ? faCheckCircle : faCircle)}
                              className={'mr-2'}
                            />
                            {payment.gateway[data].gateway_name}
                          </button>
                        </li>
                      ))}
                    </ul>
                    <hr/>
                  </React.Fragment>
                  }
                  {this.submitButton(selectGateway, selectGateway ? payment.gateway[selectGateway]: null)}
                </React.Fragment>
                }
              </React.Fragment>
            </div>
            {payment.due_date &&
              <p className={'small text-muted text-center'}>
                <TimeCounterView inverse={true} timeInterval={1000} time={payment.due_date}/> remaining.
              </p>
            }
          </div>
          :
          <React.Fragment>
            <PaymentMessage
              icon={paymentMessageIcon}
              title={paymentMessageTitle}
              description={paymentMessageDesc}
              messageClass={paymentMessageClass}
              subDescription={paymentMessageDescSub}
            />
            {payment.payment_status === Constants.PAYMENT_STATUS.PENDING &&
              <button onClick={() => this.checkPaymentStatus()} className={'btn btn-success btn-block'}>
                Check Status
              </button>
            }
            {payment.pgateway_redirect_link &&
              <a className={''} href={payment.pgateway_redirect_link}>
                Continue <FontAwesomeIcon icon={faLongArrowAltRight} className={'ml-2'}/>
              </a>
            }
            {!_.isEmpty(payment.pgateway_lock) &&
              <React.Fragment>
                <p className={'small text-muted'}>
                  Session will reset in <TimeCounterView inverse={true} time={payment.pgateway_lock.end_time} timeInterval={1000}/>.
                  Please refresh page post timeout to re-attempt the transaction.
                </p>
              </React.Fragment>
            }
          </React.Fragment>
        }
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      selectGateway: null,
      viewSection: Constants.VIEW_SECTION.CONTACT,
      userData: {},
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

export default PaymentGateways;