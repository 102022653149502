/**
 * Loading
 *
 */
import React from 'react';
import PropTypes from 'prop-types';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSpinner} from '@fortawesome/free-solid-svg-icons';

/* Component ==================================================================== */
class Loading extends React.PureComponent {
  static componentName = 'Loading';

  static propTypes = {
    heightMatch: PropTypes.bool,
  };

  static defaultProps = {
    heightMatch: true,
  };

  componentDidMount = () => {
    // do whatever you want
    this.setState({height: document.body.scrollHeight * 0.7});
  };

  render = () => {
    const {heightMatch} = this.props;
    return (
      <div className="d-flex justify-content-center" style={{height: (this.state.height && heightMatch ? this.state.height + 'px' : 'auto')}}>
        <div className="align-self-center text-center">
          <FontAwesomeIcon  icon={faSpinner} size={'2x'} pulse/>
          <p className="mt-2 small text-muted">Loading</p>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      height: null,
    };
  }
}

/* Export Component ==================================================================== */
export default Loading;
