/**
 *
 * Features Booking
 * *
 */

import React, {Component} from 'react';
import Helmet from 'react-helmet';
// Components
import ClickToView from '../components/general/ClickToView';
import Loading from '../components/general/Loading';
import Error from '../components/general/Error';
// Font-Awesome Icons
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import Footer from '../components/ui/Footer';


/* Component ==================================================================== */
class Index extends Component {

  render = () => {

    const {loading, error} = this.state;

    if (loading) return <Loading/>;
    if (error) return <Error text={error}/>;

    return (
      <div className="index screen-container site-wrapper site-wrapper-absolute">
        <Helmet>
          <title>Its an error!</title>
          <meta name="description" content="Payment request failed."/>
        </Helmet>

        <div className="container h-100">
          <div className="row h-100 justify-content-center align-items-center">
            <div className={'col-sm-12 col-md-10 col-lg-8 col-xl-6 mx-auto'}>
              <div className={' border rounded p-3 my-3'}>
                <h4>
                  <FontAwesomeIcon icon={faTimesCircle} className={'red-cl'}/> Failed !
                </h4>
                <hr/>
                <p className={'lead small text-secondary'}>Hmmm, something didn't work</p>
                <p>You are seeing this page because the payment request was cancelled or failed unexpectedly at our
                  payment gateway.</p>
                <p>Please contact the property or Renzo support
                  <ClickToView buttonClass={'a underline'} content={'support@renzo.in'}/> for more
                  information.
                </p>
                <a href="https://renzo.in" className="a">Go to Renzo</a>
              </div>
              <Footer poweredBy={false}/>
            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      showEmail: false,
    };
  }
}


export default Index;