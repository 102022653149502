/**
 * API Config
 *
 */

let hostUrl = 'https://api.renzo.in/sys-api/1';

if (process.env.REACT_APP_ENVIRONMENT === 'staging') {
  hostUrl = 'https://api-staging.renzo.in/sys-api/1';
  if (process.env.REACT_APP_API_ENDPOINT) {
    hostUrl = process.env.REACT_APP_API_ENDPOINT;
  }
}

const apis = {
  // The URL we're connecting to
  // iOS
  // 'http://192.168.0.200/api/1/pgateway'
  hostname: hostUrl,

  // Android
  // hostname: "http://10.0.2.2:8000/api/1",

  // Map shortnames to the actual endpoints, so that we can
  // use them like so: AppAPI.ENDPOINT_NAME.METHOD()
  //  NOTE: They should start with a /
  //    eg.
  //    - AppAPI.account.get()
  endpoints: new Map([
    ['pgateway', '/pgateway'],
    ['preauth', '/pgateway/preauth'],
    ['status', '/pgateway/status'],
    ['return', '/pgateway/return'],
    ['stripe', '/pgateway/stripe']
  ]),

  // Which 'endpoint' key deals with our tokens?
  tokenKey: 'auth'
};

export default apis;