const constants = {
  PAYMENT_GATEWAY_TYPE: {
    ROUTE: 0,
    DIRECT: 1,
  },
  PAYMENT_STATUS: {
    NOTPAID: 0,
    PAID: 1,
    PENDING: 2,
    ACTION: 3,
    WAITING: 4,
    ABORTED: 5,
    CANCELLED: 6,
    FAILED: 7,
  },
  PAYMENT_METHODS: {
    CASH: 1,
    CHECK: 2,
    BANK_TRANSFER: 3,
    ONLINE_PAYMENT: 4,
    CARD: 5,
    OTHER: 6,
  },
  VIEW_SECTION: {
    CONTACT: 1,
    GATEWAY_LIST: 2,
  }
};

export default constants;
