/**
 * System build information
 *  Shows information of builds, build time etc
 */
import React from 'react';
import PropTypes from 'prop-types';
import {Modal} from 'react-bootstrap';
// Consts and Libs
import APPUtil from '../../../lib/util';
import WebUtil from '../../../lib/webUtils';
import {AppConfig} from '../../../constants';

// Components
import {Alerts} from '../../../components/ui';
import renzoLogo from '../../../images/logo.png';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faSync, faTimes} from '@fortawesome/free-solid-svg-icons';


/* Component ==================================================================== */

class AuthLogin extends React.Component {
  static componentName = 'AuthLogin';

  static propTypes = {
    match: PropTypes.object,
  };


  shouldComponentUpdate(nextProps) {
    if (this.props.show !== nextProps.show) {
      this.setState({
        resultMsg: {
          status: '',
          success: '',
          error: '',
        },
      });
    }
    return true;
  }

  render = () => {

    const {resultMsg} = this.state;
    const {show} = this.props;

    if (!show) return <React.Fragment/>;

    return (
      <Modal
        {...this.props}
        size="md"
        aria-labelledby="contained-modal-title-vcenter"
        centered
        scrollable={true}
      >
        <Modal.Header closeButton>
          <Modal.Title id="contained-modal-title-vcenter">
            About Renzo
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div className="row">
            <div className="col-lg-12 col-md-12 col-sm-12 mx-auto">
              <div className={'my-4 text-center'}>
                <div className={'logo-container mb-3 mx-auto'}>
                  <img alt="Renzo" src={renzoLogo}/>
                </div>
                <h4>Renzo Payments</h4>
                <p className={'mb-1 small text-muted'}>Build : {APPUtil.formatDateTime(AppConfig.buildTime)}</p>
                <p className={'mb-1'}>
                  Version : {AppConfig.version}
                  <FontAwesomeIcon icon={faSync} className={'ml-2'} onClick={() => WebUtil.clearBrowserCache()}/>
                </p>
              </div>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className={'d-block'}>
          <div className={'px-3'}>
            <Alerts
              status={resultMsg.status}
              success={resultMsg.success}
              error={resultMsg.error}
            />
          </div>
          <div className={'row'}>
            <div className={'col'}>
              <button className={'btn btn-secondary btn-lg btn-block'} onClick={() => this.props.onHide()}>
                <FontAwesomeIcon className={'white-cl mr-2'} icon={faTimes} size={'sm'}/> Close
              </button>
            </div>
          </div>
        </Modal.Footer>
      </Modal>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}

/* Export Component ==================================================================== */
export default AuthLogin;

