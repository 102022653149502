/**
 * Payment Reducer
 *
 */

// Set initial state
const initialState = {
  payment: {},
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
  case 'SET_PAYMENT': {
    let payment = [];
    if (action.data && typeof action.data === 'object') {
      payment = action.data;
    }
    return {
      ...state,
      payment,
    };
  }
  default:
    return state;
  }
};

export default reducer;
