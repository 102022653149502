import _ from 'lodash';
import App from './App';
import React from 'react';
import ReactDOM from 'react-dom';
import * as Sentry from '@sentry/react';
import {Integrations as TracingIntegrations} from '@sentry/tracing';
// Consts and Libs
import {Strings} from './constants';

if (process.env.REACT_APP_SENTRY_URL) {
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_URL,
    release: process.env.REACT_APP_VERSION,
    environment: process.env.REACT_APP_ENVIRONMENT,
    integrations: [new TracingIntegrations.BrowserTracing()],

    tracesSampleRate: 0.1,

    beforeSend(event) {
      // Check if it is an exception, and if so, show the report dialog
      if (event.exception) {
        const options = {
          eventId: event.event_id,
          title: Strings.errorReportingTitle,
          subtitle: Strings.errorReportingSubTitle,
          subtitle2: Strings.errorReportingSubTitle2,
          successMessage: Strings.errorReportingSuccess,
        };
        if (!_.isEmpty(event.user)) {
          options.user = {
            email: event.user.email,
            name: event.user.name,
          };
        }
        Sentry.showReportDialog(options);
      }
      return event;
    },
  });
}

ReactDOM.render(
  <React.StrictMode>
    <App/>
  </React.StrictMode>,
  document.getElementById('root')
);