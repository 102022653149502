/**
 * PaymentMessage
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
// Components
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

/* Component ==================================================================== */
class PaymentMessage extends Component {
  static componentName = 'PaymentMessage';

  static propTypes = {
    icon: PropTypes.object,
    title: PropTypes.string,
    description: PropTypes.string,
    messageClass: PropTypes.string,
    subDescription: PropTypes.string,
  };

  static defaultProps = {
    messageClass: 'alert-info'
  };

  render = () => {
    const {title, description, icon, subDescription, messageClass} = this.props;
    return (
      <div className={'payment-message-wrapper'}>
        <div className={`alert ${messageClass}`}>
          <h6 className={'alert-heading'}>
            {icon && <FontAwesomeIcon icon={icon} className={'mr-1'} size={'sm'}/>} {title}
          </h6>
          {((description || subDescription) && title) && <hr/>}
          {description && <p className={'small mb-1'}>{description}</p>}
          {subDescription && <p className={'small mb-1'}>{subDescription}</p>}
        </div>
      </div>
    );
  };
}

/* Export Component ==================================================================== */
export default PaymentMessage;
