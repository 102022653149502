/**
 * Header
 */
import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import { withRouter } from 'react-router-dom';
import { Link } from 'react-router-dom';

// Components
import logo from '../../images/logo.png';

//Actions

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  user: state.user,
  property: state.property.property
});

// Any actions to map to the component?
const mapDispatchToProps = {};


/* Component ==================================================================== */
class Header extends Component {
  static componentName = 'Header';

  static propTypes = {
    message: PropTypes.string,
  };

  render = () => {

    return (
      <div className="header">
        <nav className="navbar navbar-default navbar-static-top">
          <div className="container">
            <div className="container-fluid">
              <div className="navbar-header">
                <button type="button" className="navbar-toggle collapsed" data-toggle="collapse"
                  data-target="#bs-example-navbar-collapse-1" aria-expanded="false">
                  <span className="sr-only">Toggle navigation</span>
                  <span className="icon-bar"/>
                  <span className="icon-bar"/>
                  <span className="icon-bar"/>
                </button>
                <Link className="navbar-brand" to="/dashboard">
                  <img alt="Brand" src={logo}/>
                </Link>
              </div>

              <div className="collapse navbar-collapse" id="bs-example-navbar-collapse-1">


                <ul className="nav navbar-nav navbar-right">
                </ul>
              </div>
            </div>
          </div>
        </nav>
      </div>
    );
  };
}


/* Export Component ==================================================================== */
export default connect(mapStateToProps, mapDispatchToProps)(withRouter(Header));
