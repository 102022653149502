/**
 * Payment Actions
 *
 */
import _ from 'lodash';
import AppAPI from '../../lib/api';
import * as Sentry from '@sentry/react';


/**
 * Set Payment
 */
export const setPayment = (paymentData) => (dispatch) => new Promise(async (resolve, reject) => {
  if (typeof paymentData === 'object') {
    dispatch({
      type: 'SET_PAYMENT',
      data: paymentData,
    });
    return resolve(paymentData);
  } else if (paymentData) {
    // Fetch Payment Data
    return AppAPI.pgateway.get(`pgateway/${paymentData}/`)
      .then((res) => {
        if (!_.isEmpty(res.property)) {
          Sentry.setContext('property', {
            Name: res.property.name,
          });
        }

        Sentry.setContext('payment', {
          Hash: res.hash,
          Reference: res.ref_no,
        });
        dispatch({
          type: 'SET_PAYMENT',
          data: res,
        });
        return resolve(res);
      })
      .catch(err => reject(err));
  }

  return reject();
});

/**
 * Update Payment
 */
export const updatePayment = (paymentData) => setPayment(paymentData);

/**
 * Remove Payment
 */
export function unSetPayment() {
  return (dispatch) => {
    dispatch({
      type: 'SET_PAYMENT',
      data: null,
    });
  };
}


/**
 * Check Payment
 */
export const checkPaymentStatus = (paymentData) => (dispatch) => new Promise(async (resolve, reject) => {
  return AppAPI.pgateway.post(`pgateway/${paymentData}/status`, {})
    .then((res) => {
      dispatch({
        type: 'SET_PAYMENT',
        data: res,
      });
      return resolve(res);
    })
    .catch(err => reject(err));
});

