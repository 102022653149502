/**
 * Created by anush on 22/06/17.
 *
 * Combine All Reducers
 *
 */

import { combineReducers } from 'redux';

// Our custom reducers
// We need to import each one here and add them to the combiner at the bottom
import payment from './payment/reducer';


// Combine all
const appReducer = combineReducers({
  payment
});

// Setup root reducer
const rootReducer = (state, action) => {
  const newState = (action.type === 'RESET') ? undefined : state;
  return appReducer(newState, action);
};

export default rootReducer;

