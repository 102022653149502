const countries = [
  {
    'code': 'AD',
    'name': 'Andorra',
    'currency': 'EUR',
    'phone': '+376'
  },
  {
    'code': 'AE',
    'name': 'United Arab Emirates',
    'currency': 'AED',
    'phone': '+971'
  },
  {
    'code': 'AF',
    'name': 'Afghanistan',
    'currency': 'AFN',
    'phone': '+93'
  },
  {
    'code': 'AG',
    'name': 'Antigua and Barbuda',
    'currency': 'XCD',
    'phone': '+1-268'
  },
  {
    'code': 'AI',
    'name': 'Anguilla',
    'currency': 'XCD',
    'phone': '+1-264'
  },
  {
    'code': 'AL',
    'name': 'Albania',
    'currency': 'ALL',
    'phone': '+355'
  },
  {
    'code': 'AM',
    'name': 'Armenia',
    'currency': 'AMD',
    'phone': '+374'
  },
  {
    'code': 'AO',
    'name': 'Angola',
    'currency': 'AOA',
    'phone': '+244'
  },
  {
    'code': 'AQ',
    'name': 'Antarctica',
    'currency': '',
    'phone': ''
  },
  {
    'code': 'AR',
    'name': 'Argentina',
    'currency': 'ARS',
    'phone': '+54'
  },
  {
    'code': 'AS',
    'name': 'American Samoa',
    'currency': 'USD',
    'phone': '+1-684'
  },
  {
    'code': 'AT',
    'name': 'Austria',
    'currency': 'EUR',
    'phone': '+43'
  },
  {
    'code': 'AU',
    'name': 'Australia',
    'currency': 'AUD',
    'phone': '+61'
  },
  {
    'code': 'AW',
    'name': 'Aruba',
    'currency': 'AWG',
    'phone': '+297'
  },
  {
    'code': 'AX',
    'name': 'Aland Islands',
    'currency': 'EUR',
    'phone': '+358-18'
  },
  {
    'code': 'AZ',
    'name': 'Azerbaijan',
    'currency': 'AZN',
    'phone': '+994'
  },
  {
    'code': 'BA',
    'name': 'Bosnia and Herzegovina',
    'currency': 'BAM',
    'phone': '+387'
  },
  {
    'code': 'BB',
    'name': 'Barbados',
    'currency': 'BBD',
    'phone': '+1-246'
  },
  {
    'code': 'BD',
    'name': 'Bangladesh',
    'currency': 'BDT',
    'phone': '+880'
  },
  {
    'code': 'BE',
    'name': 'Belgium',
    'currency': 'EUR',
    'phone': '+32'
  },
  {
    'code': 'BF',
    'name': 'Burkina Faso',
    'currency': 'XOF',
    'phone': '+226'
  },
  {
    'code': 'BG',
    'name': 'Bulgaria',
    'currency': 'BGN',
    'phone': '+359'
  },
  {
    'code': 'BH',
    'name': 'Bahrain',
    'currency': 'BHD',
    'phone': '+973'
  },
  {
    'code': 'BI',
    'name': 'Burundi',
    'currency': 'BIF',
    'phone': '+257'
  },
  {
    'code': 'BJ',
    'name': 'Benin',
    'currency': 'XOF',
    'phone': '+229'
  },
  {
    'code': 'BL',
    'name': 'Saint Barthelemy',
    'currency': 'EUR',
    'phone': '+590'
  },
  {
    'code': 'BM',
    'name': 'Bermuda',
    'currency': 'BMD',
    'phone': '+1-441'
  },
  {
    'code': 'BN',
    'name': 'Brunei',
    'currency': 'BND',
    'phone': '+673'
  },
  {
    'code': 'BO',
    'name': 'Bolivia',
    'currency': 'BOB',
    'phone': '+591'
  },
  {
    'code': 'BQ',
    'name': 'Bonaire, Saint Eustatius and Saba ',
    'currency': 'USD',
    'phone': '+599'
  },
  {
    'code': 'BR',
    'name': 'Brazil',
    'currency': 'BRL',
    'phone': '+55'
  },
  {
    'code': 'BS',
    'name': 'Bahamas',
    'currency': 'BSD',
    'phone': '+1-242'
  },
  {
    'code': 'BT',
    'name': 'Bhutan',
    'currency': 'BTN',
    'phone': '+975'
  },
  {
    'code': 'BV',
    'name': 'Bouvet Island',
    'currency': 'NOK',
    'phone': ''
  },
  {
    'code': 'BW',
    'name': 'Botswana',
    'currency': 'BWP',
    'phone': '+267'
  },
  {
    'code': 'BY',
    'name': 'Belarus',
    'currency': 'BYR',
    'phone': '+375'
  },
  {
    'code': 'BZ',
    'name': 'Belize',
    'currency': 'BZD',
    'phone': '+501'
  },
  {
    'code': 'CA',
    'name': 'Canada',
    'currency': 'CAD',
    'phone': '+1'
  },
  {
    'code': 'CC',
    'name': 'Cocos Islands',
    'currency': 'AUD',
    'phone': '+61'
  },
  {
    'code': 'CD',
    'name': 'Democratic Republic of the Congo',
    'currency': 'CDF',
    'phone': '+243'
  },
  {
    'code': 'CF',
    'name': 'Central African Republic',
    'currency': 'XAF',
    'phone': '+236'
  },
  {
    'code': 'CG',
    'name': 'Republic of the Congo',
    'currency': 'XAF',
    'phone': '+242'
  },
  {
    'code': 'CH',
    'name': 'Switzerland',
    'currency': 'CHF',
    'phone': '+41'
  },
  {
    'code': 'CI',
    'name': 'Ivory Coast',
    'currency': 'XOF',
    'phone': '+225'
  },
  {
    'code': 'CK',
    'name': 'Cook Islands',
    'currency': 'NZD',
    'phone': '+682'
  },
  {
    'code': 'CL',
    'name': 'Chile',
    'currency': 'CLP',
    'phone': '+56'
  },
  {
    'code': 'CM',
    'name': 'Cameroon',
    'currency': 'XAF',
    'phone': '+237'
  },
  {
    'code': 'CN',
    'name': 'China',
    'currency': 'CNY',
    'phone': '+86'
  },
  {
    'code': 'CO',
    'name': 'Colombia',
    'currency': 'COP',
    'phone': '+57'
  },
  {
    'code': 'CR',
    'name': 'Costa Rica',
    'currency': 'CRC',
    'phone': '+506'
  },
  {
    'code': 'CU',
    'name': 'Cuba',
    'currency': 'CUP',
    'phone': '+53'
  },
  {
    'code': 'CV',
    'name': 'Cape Verde',
    'currency': 'CVE',
    'phone': '+238'
  },
  {
    'code': 'CW',
    'name': 'Curacao',
    'currency': 'ANG',
    'phone': '+599'
  },
  {
    'code': 'CX',
    'name': 'Christmas Island',
    'currency': 'AUD',
    'phone': '+61'
  },
  {
    'code': 'CY',
    'name': 'Cyprus',
    'currency': 'EUR',
    'phone': '+357'
  },
  {
    'code': 'CZ',
    'name': 'Czech Republic',
    'currency': 'CZK',
    'phone': '+420'
  },
  {
    'code': 'DE',
    'name': 'Germany',
    'currency': 'EUR',
    'phone': '+49'
  },
  {
    'code': 'DJ',
    'name': 'Djibouti',
    'currency': 'DJF',
    'phone': '+253'
  },
  {
    'code': 'DK',
    'name': 'Denmark',
    'currency': 'DKK',
    'phone': '+45'
  },
  {
    'code': 'DM',
    'name': 'Dominica',
    'currency': 'XCD',
    'phone': '+1-767'
  },
  {
    'code': 'DO',
    'name': 'Dominican Republic',
    'currency': 'DOP',
    'phone': '+1-809 and 1-829'
  },
  {
    'code': 'DZ',
    'name': 'Algeria',
    'currency': 'DZD',
    'phone': '+213'
  },
  {
    'code': 'EC',
    'name': 'Ecuador',
    'currency': 'USD',
    'phone': '+593'
  },
  {
    'code': 'EE',
    'name': 'Estonia',
    'currency': 'EUR',
    'phone': '+372'
  },
  {
    'code': 'EG',
    'name': 'Egypt',
    'currency': 'EGP',
    'phone': '+20'
  },
  {
    'code': 'EH',
    'name': 'Western Sahara',
    'currency': 'MAD',
    'phone': '+212'
  },
  {
    'code': 'ER',
    'name': 'Eritrea',
    'currency': 'ERN',
    'phone': '+291'
  },
  {
    'code': 'ES',
    'name': 'Spain',
    'currency': 'EUR',
    'phone': '+34'
  },
  {
    'code': 'ET',
    'name': 'Ethiopia',
    'currency': 'ETB',
    'phone': '+251'
  },
  {
    'code': 'FI',
    'name': 'Finland',
    'currency': 'EUR',
    'phone': '+358'
  },
  {
    'code': 'FJ',
    'name': 'Fiji',
    'currency': 'FJD',
    'phone': '+679'
  },
  {
    'code': 'FK',
    'name': 'Falkland Islands',
    'currency': 'FKP',
    'phone': '+500'
  },
  {
    'code': 'FM',
    'name': 'Micronesia',
    'currency': 'USD',
    'phone': '+691'
  },
  {
    'code': 'FO',
    'name': 'Faroe Islands',
    'currency': 'DKK',
    'phone': '+298'
  },
  {
    'code': 'FR',
    'name': 'France',
    'currency': 'EUR',
    'phone': '+33'
  },
  {
    'code': 'GA',
    'name': 'Gabon',
    'currency': 'XAF',
    'phone': '+241'
  },
  {
    'code': 'GB',
    'name': 'United Kingdom',
    'currency': 'GBP',
    'phone': '+44'
  },
  {
    'code': 'GD',
    'name': 'Grenada',
    'currency': 'XCD',
    'phone': '+1-473'
  },
  {
    'code': 'GE',
    'name': 'Georgia',
    'currency': 'GEL',
    'phone': '+995'
  },
  {
    'code': 'GF',
    'name': 'French Guiana',
    'currency': 'EUR',
    'phone': '+594'
  },
  {
    'code': 'GG',
    'name': 'Guernsey',
    'currency': 'GBP',
    'phone': '+44-1481'
  },
  {
    'code': 'GH',
    'name': 'Ghana',
    'currency': 'GHS',
    'phone': '+233'
  },
  {
    'code': 'GI',
    'name': 'Gibraltar',
    'currency': 'GIP',
    'phone': '+350'
  },
  {
    'code': 'GL',
    'name': 'Greenland',
    'currency': 'DKK',
    'phone': '+299'
  },
  {
    'code': 'GM',
    'name': 'Gambia',
    'currency': 'GMD',
    'phone': '+220'
  },
  {
    'code': 'GN',
    'name': 'Guinea',
    'currency': 'GNF',
    'phone': '+224'
  },
  {
    'code': 'GP',
    'name': 'Guadeloupe',
    'currency': 'EUR',
    'phone': '+590'
  },
  {
    'code': 'GQ',
    'name': 'Equatorial Guinea',
    'currency': 'XAF',
    'phone': '+240'
  },
  {
    'code': 'GR',
    'name': 'Greece',
    'currency': 'EUR',
    'phone': '+30'
  },
  {
    'code': 'GS',
    'name': 'South Georgia and the South Sandwich Islands',
    'currency': 'GBP',
    'phone': ''
  },
  {
    'code': 'GT',
    'name': 'Guatemala',
    'currency': 'GTQ',
    'phone': '+502'
  },
  {
    'code': 'GU',
    'name': 'Guam',
    'currency': 'USD',
    'phone': '+1-671'
  },
  {
    'code': 'GW',
    'name': 'Guinea-Bissau',
    'currency': 'XOF',
    'phone': '+245'
  },
  {
    'code': 'GY',
    'name': 'Guyana',
    'currency': 'GYD',
    'phone': '+592'
  },
  {
    'code': 'HK',
    'name': 'Hong Kong',
    'currency': 'HKD',
    'phone': '+852'
  },
  {
    'code': 'HM',
    'name': 'Heard Island and McDonald Islands',
    'currency': 'AUD',
    'phone': '+ '
  },
  {
    'code': 'HN',
    'name': 'Honduras',
    'currency': 'HNL',
    'phone': '+504'
  },
  {
    'code': 'HR',
    'name': 'Croatia',
    'currency': 'HRK',
    'phone': '+385'
  },
  {
    'code': 'HT',
    'name': 'Haiti',
    'currency': 'HTG',
    'phone': '+509'
  },
  {
    'code': 'HU',
    'name': 'Hungary',
    'currency': 'HUF',
    'phone': '+36'
  },
  {
    'code': 'ID',
    'name': 'Indonesia',
    'currency': 'IDR',
    'phone': '+62'
  },
  {
    'code': 'IE',
    'name': 'Ireland',
    'currency': 'EUR',
    'phone': '+353'
  },
  {
    'code': 'IL',
    'name': 'Israel',
    'currency': 'ILS',
    'phone': '+972'
  },
  {
    'code': 'IM',
    'name': 'Isle of Man',
    'currency': 'GBP',
    'phone': '+44-1624'
  },
  {
    'code': 'IN',
    'name': 'India',
    'currency': 'INR',
    'phone': '+91'
  },
  {
    'code': 'IO',
    'name': 'British Indian Ocean Territory',
    'currency': 'USD',
    'phone': '+246'
  },
  {
    'code': 'IQ',
    'name': 'Iraq',
    'currency': 'IQD',
    'phone': '+964'
  },
  {
    'code': 'IR',
    'name': 'Iran',
    'currency': 'IRR',
    'phone': '+98'
  },
  {
    'code': 'IS',
    'name': 'Iceland',
    'currency': 'ISK',
    'phone': '+354'
  },
  {
    'code': 'IT',
    'name': 'Italy',
    'currency': 'EUR',
    'phone': '+39'
  },
  {
    'code': 'JE',
    'name': 'Jersey',
    'currency': 'GBP',
    'phone': '+44-1534'
  },
  {
    'code': 'JM',
    'name': 'Jamaica',
    'currency': 'JMD',
    'phone': '+1-876'
  },
  {
    'code': 'JO',
    'name': 'Jordan',
    'currency': 'JOD',
    'phone': '+962'
  },
  {
    'code': 'JP',
    'name': 'Japan',
    'currency': 'JPY',
    'phone': '+81'
  },
  {
    'code': 'KE',
    'name': 'Kenya',
    'currency': 'KES',
    'phone': '+254'
  },
  {
    'code': 'KG',
    'name': 'Kyrgyzstan',
    'currency': 'KGS',
    'phone': '+996'
  },
  {
    'code': 'KH',
    'name': 'Cambodia',
    'currency': 'KHR',
    'phone': '+855'
  },
  {
    'code': 'KI',
    'name': 'Kiribati',
    'currency': 'AUD',
    'phone': '+686'
  },
  {
    'code': 'KM',
    'name': 'Comoros',
    'currency': 'KMF',
    'phone': '+269'
  },
  {
    'code': 'KN',
    'name': 'Saint Kitts and Nevis',
    'currency': 'XCD',
    'phone': '+1-869'
  },
  {
    'code': 'KP',
    'name': 'North Korea',
    'currency': 'KPW',
    'phone': '+850'
  },
  {
    'code': 'KR',
    'name': 'South Korea',
    'currency': 'KRW',
    'phone': '+82'
  },
  {
    'code': 'KW',
    'name': 'Kuwait',
    'currency': 'KWD',
    'phone': '+965'
  },
  {
    'code': 'KY',
    'name': 'Cayman Islands',
    'currency': 'KYD',
    'phone': '+1-345'
  },
  {
    'code': 'KZ',
    'name': 'Kazakhstan',
    'currency': 'KZT',
    'phone': '+7'
  },
  {
    'code': 'LA',
    'name': 'Laos',
    'currency': 'LAK',
    'phone': '+856'
  },
  {
    'code': 'LB',
    'name': 'Lebanon',
    'currency': 'LBP',
    'phone': '+961'
  },
  {
    'code': 'LC',
    'name': 'Saint Lucia',
    'currency': 'XCD',
    'phone': '+1-758'
  },
  {
    'code': 'LI',
    'name': 'Liechtenstein',
    'currency': 'CHF',
    'phone': '+423'
  },
  {
    'code': 'LK',
    'name': 'Sri Lanka',
    'currency': 'LKR',
    'phone': '+94'
  },
  {
    'code': 'LR',
    'name': 'Liberia',
    'currency': 'LRD',
    'phone': '+231'
  },
  {
    'code': 'LS',
    'name': 'Lesotho',
    'currency': 'LSL',
    'phone': '+266'
  },
  {
    'code': 'LT',
    'name': 'Lithuania',
    'currency': 'LTL',
    'phone': '+370'
  },
  {
    'code': 'LU',
    'name': 'Luxembourg',
    'currency': 'EUR',
    'phone': '+352'
  },
  {
    'code': 'LV',
    'name': 'Latvia',
    'currency': 'EUR',
    'phone': '+371'
  },
  {
    'code': 'LY',
    'name': 'Libya',
    'currency': 'LYD',
    'phone': '+218'
  },
  {
    'code': 'MA',
    'name': 'Morocco',
    'currency': 'MAD',
    'phone': '+212'
  },
  {
    'code': 'MC',
    'name': 'Monaco',
    'currency': 'EUR',
    'phone': '+377'
  },
  {
    'code': 'MD',
    'name': 'Moldova',
    'currency': 'MDL',
    'phone': '+373'
  },
  {
    'code': 'ME',
    'name': 'Montenegro',
    'currency': 'EUR',
    'phone': '+382'
  },
  {
    'code': 'MF',
    'name': 'Saint Martin',
    'currency': 'EUR',
    'phone': '+590'
  },
  {
    'code': 'MG',
    'name': 'Madagascar',
    'currency': 'MGA',
    'phone': '+261'
  },
  {
    'code': 'MH',
    'name': 'Marshall Islands',
    'currency': 'USD',
    'phone': '+692'
  },
  {
    'code': 'MK',
    'name': 'Macedonia',
    'currency': 'MKD',
    'phone': '+389'
  },
  {
    'code': 'ML',
    'name': 'Mali',
    'currency': 'XOF',
    'phone': '+223'
  },
  {
    'code': 'MM',
    'name': 'Myanmar',
    'currency': 'MMK',
    'phone': '+95'
  },
  {
    'code': 'MN',
    'name': 'Mongolia',
    'currency': 'MNT',
    'phone': '+976'
  },
  {
    'code': 'MO',
    'name': 'Macao',
    'currency': 'MOP',
    'phone': '+853'
  },
  {
    'code': 'MP',
    'name': 'Northern Mariana Islands',
    'currency': 'USD',
    'phone': '+1-670'
  },
  {
    'code': 'MQ',
    'name': 'Martinique',
    'currency': 'EUR',
    'phone': '+596'
  },
  {
    'code': 'MR',
    'name': 'Mauritania',
    'currency': 'MRO',
    'phone': '+222'
  },
  {
    'code': 'MS',
    'name': 'Montserrat',
    'currency': 'XCD',
    'phone': '+1-664'
  },
  {
    'code': 'MT',
    'name': 'Malta',
    'currency': 'EUR',
    'phone': '+356'
  },
  {
    'code': 'MU',
    'name': 'Mauritius',
    'currency': 'MUR',
    'phone': '+230'
  },
  {
    'code': 'MV',
    'name': 'Maldives',
    'currency': 'MVR',
    'phone': '+960'
  },
  {
    'code': 'MW',
    'name': 'Malawi',
    'currency': 'MWK',
    'phone': '+265'
  },
  {
    'code': 'MX',
    'name': 'Mexico',
    'currency': 'MXN',
    'phone': '+52'
  },
  {
    'code': 'MY',
    'name': 'Malaysia',
    'currency': 'MYR',
    'phone': '+60'
  },
  {
    'code': 'MZ',
    'name': 'Mozambique',
    'currency': 'MZN',
    'phone': '+258'
  },
  {
    'code': 'NA',
    'name': 'Namibia',
    'currency': 'NAD',
    'phone': '+264'
  },
  {
    'code': 'NC',
    'name': 'New Caledonia',
    'currency': 'XPF',
    'phone': '+687'
  },
  {
    'code': 'NE',
    'name': 'Niger',
    'currency': 'XOF',
    'phone': '+227'
  },
  {
    'code': 'NF',
    'name': 'Norfolk Island',
    'currency': 'AUD',
    'phone': '+672'
  },
  {
    'code': 'NG',
    'name': 'Nigeria',
    'currency': 'NGN',
    'phone': '+234'
  },
  {
    'code': 'NI',
    'name': 'Nicaragua',
    'currency': 'NIO',
    'phone': '+505'
  },
  {
    'code': 'NL',
    'name': 'Netherlands',
    'currency': 'EUR',
    'phone': '+31'
  },
  {
    'code': 'NO',
    'name': 'Norway',
    'currency': 'NOK',
    'phone': '+47'
  },
  {
    'code': 'NP',
    'name': 'Nepal',
    'currency': 'NPR',
    'phone': '+977'
  },
  {
    'code': 'NR',
    'name': 'Nauru',
    'currency': 'AUD',
    'phone': '+674'
  },
  {
    'code': 'NU',
    'name': 'Niue',
    'currency': 'NZD',
    'phone': '+683'
  },
  {
    'code': 'NZ',
    'name': 'New Zealand',
    'currency': 'NZD',
    'phone': '+64'
  },
  {
    'code': 'OM',
    'name': 'Oman',
    'currency': 'OMR',
    'phone': '+968'
  },
  {
    'code': 'PA',
    'name': 'Panama',
    'currency': 'PAB',
    'phone': '+507'
  },
  {
    'code': 'PE',
    'name': 'Peru',
    'currency': 'PEN',
    'phone': '+51'
  },
  {
    'code': 'PF',
    'name': 'French Polynesia',
    'currency': 'XPF',
    'phone': '+689'
  },
  {
    'code': 'PG',
    'name': 'Papua New Guinea',
    'currency': 'PGK',
    'phone': '+675'
  },
  {
    'code': 'PH',
    'name': 'Philippines',
    'currency': 'PHP',
    'phone': '+63'
  },
  {
    'code': 'PK',
    'name': 'Pakistan',
    'currency': 'PKR',
    'phone': '+92'
  },
  {
    'code': 'PL',
    'name': 'Poland',
    'currency': 'PLN',
    'phone': '+48'
  },
  {
    'code': 'PM',
    'name': 'Saint Pierre and Miquelon',
    'currency': 'EUR',
    'phone': '+508'
  },
  {
    'code': 'PN',
    'name': 'Pitcairn',
    'currency': 'NZD',
    'phone': '+870'
  },
  {
    'code': 'PR',
    'name': 'Puerto Rico',
    'currency': 'USD',
    'phone': '+1-787 and 1-939'
  },
  {
    'code': 'PS',
    'name': 'Palestinian Territory',
    'currency': 'ILS',
    'phone': '+970'
  },
  {
    'code': 'PT',
    'name': 'Portugal',
    'currency': 'EUR',
    'phone': '+351'
  },
  {
    'code': 'PW',
    'name': 'Palau',
    'currency': 'USD',
    'phone': '+680'
  },
  {
    'code': 'PY',
    'name': 'Paraguay',
    'currency': 'PYG',
    'phone': '+595'
  },
  {
    'code': 'QA',
    'name': 'Qatar',
    'currency': 'QAR',
    'phone': '+974'
  },
  {
    'code': 'RE',
    'name': 'Reunion',
    'currency': 'EUR',
    'phone': '+262'
  },
  {
    'code': 'RO',
    'name': 'Romania',
    'currency': 'RON',
    'phone': '+40'
  },
  {
    'code': 'RS',
    'name': 'Serbia',
    'currency': 'RSD',
    'phone': '+381'
  },
  {
    'code': 'RU',
    'name': 'Russia',
    'currency': 'RUB',
    'phone': '+7'
  },
  {
    'code': 'RW',
    'name': 'Rwanda',
    'currency': 'RWF',
    'phone': '+250'
  },
  {
    'code': 'SA',
    'name': 'Saudi Arabia',
    'currency': 'SAR',
    'phone': '+966'
  },
  {
    'code': 'SB',
    'name': 'Solomon Islands',
    'currency': 'SBD',
    'phone': '+677'
  },
  {
    'code': 'SC',
    'name': 'Seychelles',
    'currency': 'SCR',
    'phone': '+248'
  },
  {
    'code': 'SD',
    'name': 'Sudan',
    'currency': 'SDG',
    'phone': '+249'
  },
  {
    'code': 'SE',
    'name': 'Sweden',
    'currency': 'SEK',
    'phone': '+46'
  },
  {
    'code': 'SG',
    'name': 'Singapore',
    'currency': 'SGD',
    'phone': '+65'
  },
  {
    'code': 'SH',
    'name': 'Saint Helena',
    'currency': 'SHP',
    'phone': '+290'
  },
  {
    'code': 'SI',
    'name': 'Slovenia',
    'currency': 'EUR',
    'phone': '+386'
  },
  {
    'code': 'SJ',
    'name': 'Svalbard and Jan Mayen',
    'currency': 'NOK',
    'phone': '+47'
  },
  {
    'code': 'SK',
    'name': 'Slovakia',
    'currency': 'EUR',
    'phone': '+421'
  },
  {
    'code': 'SL',
    'name': 'Sierra Leone',
    'currency': 'SLL',
    'phone': '+232'
  },
  {
    'code': 'SM',
    'name': 'San Marino',
    'currency': 'EUR',
    'phone': '+378'
  },
  {
    'code': 'SN',
    'name': 'Senegal',
    'currency': 'XOF',
    'phone': '+221'
  },
  {
    'code': 'SO',
    'name': 'Somalia',
    'currency': 'SOS',
    'phone': '+252'
  },
  {
    'code': 'SR',
    'name': 'Suriname',
    'currency': 'SRD',
    'phone': '+597'
  },
  {
    'code': 'SS',
    'name': 'South Sudan',
    'currency': 'SSP',
    'phone': '+211'
  },
  {
    'code': 'ST',
    'name': 'Sao Tome and Principe',
    'currency': 'STD',
    'phone': '+239'
  },
  {
    'code': 'SV',
    'name': 'El Salvador',
    'currency': 'USD',
    'phone': '+503'
  },
  {
    'code': 'SX',
    'name': 'Sint Maarten',
    'currency': 'ANG',
    'phone': '+599'
  },
  {
    'code': 'SY',
    'name': 'Syria',
    'currency': 'SYP',
    'phone': '+963'
  },
  {
    'code': 'SZ',
    'name': 'Swaziland',
    'currency': 'SZL',
    'phone': '+268'
  },
  {
    'code': 'TC',
    'name': 'Turks and Caicos Islands',
    'currency': 'USD',
    'phone': '+1-649'
  },
  {
    'code': 'TD',
    'name': 'Chad',
    'currency': 'XAF',
    'phone': '+235'
  },
  {
    'code': 'TF',
    'name': 'French Southern Territories',
    'currency': 'EUR',
    'phone': ''
  },
  {
    'code': 'TG',
    'name': 'Togo',
    'currency': 'XOF',
    'phone': '+228'
  },
  {
    'code': 'TH',
    'name': 'Thailand',
    'currency': 'THB',
    'phone': '+66'
  },
  {
    'code': 'TJ',
    'name': 'Tajikistan',
    'currency': 'TJS',
    'phone': '+992'
  },
  {
    'code': 'TK',
    'name': 'Tokelau',
    'currency': 'NZD',
    'phone': '+690'
  },
  {
    'code': 'TL',
    'name': 'East Timor',
    'currency': 'USD',
    'phone': '+670'
  },
  {
    'code': 'TM',
    'name': 'Turkmenistan',
    'currency': 'TMT',
    'phone': '+993'
  },
  {
    'code': 'TN',
    'name': 'Tunisia',
    'currency': 'TND',
    'phone': '+216'
  },
  {
    'code': 'TO',
    'name': 'Tonga',
    'currency': 'TOP',
    'phone': '+676'
  },
  {
    'code': 'TR',
    'name': 'Turkey',
    'currency': 'TRY',
    'phone': '+90'
  },
  {
    'code': 'TT',
    'name': 'Trinidad and Tobago',
    'currency': 'TTD',
    'phone': '+1-868'
  },
  {
    'code': 'TV',
    'name': 'Tuvalu',
    'currency': 'AUD',
    'phone': '+688'
  },
  {
    'code': 'TW',
    'name': 'Taiwan',
    'currency': 'TWD',
    'phone': '+886'
  },
  {
    'code': 'TZ',
    'name': 'Tanzania',
    'currency': 'TZS',
    'phone': '+255'
  },
  {
    'code': 'UA',
    'name': 'Ukraine',
    'currency': 'UAH',
    'phone': '+380'
  },
  {
    'code': 'UG',
    'name': 'Uganda',
    'currency': 'UGX',
    'phone': '+256'
  },
  {
    'code': 'UM',
    'name': 'United States Minor Outlying Islands',
    'currency': 'USD',
    'phone': '+1'
  },
  {
    'code': 'US',
    'name': 'United States',
    'currency': 'USD',
    'phone': '+1'
  },
  {
    'code': 'UY',
    'name': 'Uruguay',
    'currency': 'UYU',
    'phone': '+598'
  },
  {
    'code': 'UZ',
    'name': 'Uzbekistan',
    'currency': 'UZS',
    'phone': '+998'
  },
  {
    'code': 'VA',
    'name': 'Vatican',
    'currency': 'EUR',
    'phone': '+379'
  },
  {
    'code': 'VC',
    'name': 'Saint Vincent and the Grenadines',
    'currency': 'XCD',
    'phone': '+1-784'
  },
  {
    'code': 'VE',
    'name': 'Venezuela',
    'currency': 'VEF',
    'phone': '+58'
  },
  {
    'code': 'VG',
    'name': 'British Virgin Islands',
    'currency': 'USD',
    'phone': '+1-284'
  },
  {
    'code': 'VI',
    'name': 'U.S. Virgin Islands',
    'currency': 'USD',
    'phone': '+1-340'
  },
  {
    'code': 'VN',
    'name': 'Vietnam',
    'currency': 'VND',
    'phone': '+84'
  },
  {
    'code': 'VU',
    'name': 'Vanuatu',
    'currency': 'VUV',
    'phone': '+678'
  },
  {
    'code': 'WF',
    'name': 'Wallis and Futuna',
    'currency': 'XPF',
    'phone': '+681'
  },
  {
    'code': 'WS',
    'name': 'Samoa',
    'currency': 'WST',
    'phone': '+685'
  },
  {
    'code': 'XK',
    'name': 'Kosovo',
    'currency': 'EUR',
    'phone': ''
  },
  {
    'code': 'YE',
    'name': 'Yemen',
    'currency': 'YER',
    'phone': '+967'
  },
  {
    'code': 'YT',
    'name': 'Mayotte',
    'currency': 'EUR',
    'phone': '+262'
  },
  {
    'code': 'ZA',
    'name': 'South Africa',
    'currency': 'ZAR',
    'phone': '+27'
  },
  {
    'code': 'ZM',
    'name': 'Zambia',
    'currency': 'ZMK',
    'phone': '+260'
  },
  {
    'code': 'ZW',
    'name': 'Zimbabwe',
    'currency': 'ZWL',
    'phone': '+263'
  }
];

export default countries;