/**
 * App Error Messages
 *
 */

const error =  {
  // Defaults
  default: 'Hmm, an unknown error occurred',
  notfound: 'The link your are trying to access is not valid.',
};


export default error;