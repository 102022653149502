/**
 *
 * Payments not found
 * *
 */
import _ from 'lodash';
import React, {Component} from 'react';
import Helmet from 'react-helmet';

// Components
import Loading from '../components/general/Loading';

import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faTimesCircle} from '@fortawesome/free-solid-svg-icons';
import Footer from '../components/ui/Footer';


/* Component ==================================================================== */
class Index extends Component {

  render = () => {
    const {error} = this.props;
    const {loading} = this.state;

    if (loading) return <Loading/>;

    return (
      <div className="index screen-container site-wrapper site-wrapper-absolute">
        <Helmet>
          <title>Its an error!</title>
          <meta name="description" content="The content you are looking for is not found."/>
        </Helmet>

        <div className="site-wrapper-inner">
          <div className="cover-container">
            <div className={'container row mx-auto'}>
              <div className={'col-lg-7 col-md-10 col-sm-12 mx-auto'}>
                <div className={' border rounded p-3 my-3'}>
                  <h4>
                    <FontAwesomeIcon className={'red-cl'} icon={faTimesCircle} size={'sm'}/> Error !
                  </h4>
                  <hr/>
                  <p className={'lead small text-secondary'}>Hmmm, something didn't work</p>
                  <p>You are seeing this page because the payment link you requested does not exist or is cancelled.</p>
                  <p>Please contact the property for more information.</p>
                  {!_.isEmpty(error) && <p>{error}</p>}
                </div>
                <Footer poweredBy={true}/>
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      loading: false,
      error: false,
    };
  }
}


export default Index;