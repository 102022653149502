/**
 * String database
 *
 */

const Strings = {
  // Error Reporting
  errorReportingTitle: 'Oops, Looks like something is broken.',
  errorReportingSubTitle: 'We apologise for the inconvenience caused. It would help our team speed up the fixing, if you could inform us what exactly happened.',
  errorReportingSubTitle2: '',
  errorReportingSuccess: 'Thank you for your valuable feedback, our team is working to fix the problem.',
};
export default Strings;
