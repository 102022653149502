/**
 * Time Counter View
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Moment from 'moment';

/* Component ==================================================================== */
class TimeCounterView extends Component {
  static componentName = 'TimeCounterView';

  static propTypes = {
    time: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    time2: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
    inverse: PropTypes.bool,
    timeInterval: PropTypes.number,
    displayType: PropTypes.string,
  };

  static defaultProps = {
    time: Moment(),
    time2: null,
    inverse: false,
    timeInterval: 5000,
    displayType: 'day'
  }

  componentDidMount() {
    const {time2, timeInterval} = this.props;

    this.interval = setInterval(() => {
      let currentTime = Moment();
      if (time2) {
        currentTime = Moment(time2);
      }
      this.setState({currentTime: currentTime});
    }, timeInterval);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }


  render = () => {
    const {time, displayType, inverse} = this.props;
    const {currentTime} = this.state;
    let timeDiff = inverse ? Moment(time).diff(currentTime) : currentTime.diff(Moment(time));


    let displayText = [];

    const duration = Moment.duration(timeDiff);

    switch (displayType) {
    case 'day':
      if (duration.get('day') > 0) {displayText.push(`${duration.get('day')} Day`);}
      // eslint-disable-next-line no-fallthrough
    case 'hour':
      if (duration.get('hour') > 0) {displayText.push(`${duration.get('hour')} Hr`);}
      // eslint-disable-next-line no-fallthrough
    case 'minute':
      if (duration.get('minute') > 0) { displayText.push(`${duration.get('minute')} Min`);}
      // eslint-disable-next-line no-fallthrough
    case 'second':
      if (duration.get('second') > 0) { displayText.push(`${duration.get('second')} Sec`);}
      break;
    default:
      if (duration.get('minute') > 0) { displayText.push(`${duration.get('minute')} Min`); }
    }

    if (inverse && timeDiff <= 0) {
      displayText.push('0 Seconds.');
      clearInterval(this.interval);
    }

    return (
      <React.Fragment>
        {displayText.join(', ')}
      </React.Fragment>
    );
  };

  constructor(props) {
    super(props);

    let currentTime = Moment();
    if (this.props.time2) {
      currentTime = Moment(this.props.time2);
    }

    this.state = {
      currentTime: currentTime
    };
  }
}

/* Export Component ==================================================================== */
export default TimeCounterView;