/**
 * Footer
 */
import React from 'react';
import PropTypes from 'prop-types';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {faDotCircle} from '@fortawesome/free-solid-svg-icons';
import BuildInformation from './model/BuildInformation';
import renzoLogo from '../../images/logo.png';

/* Component ==================================================================== */
class Footer extends React.PureComponent {

  static propTypes = {
    poweredBy: PropTypes.bool,
  };

  render = () => {
    return (
      <div className="footer mb-sm-5">
        <div className={'row'}>
          <div className="col-12 col-md-9 mx-auto">
            <ul className={'list-inline text-left mt-3'}>
              <li className="list-inline-item">
                <a className={'small text-secondary'} rel='noopener noreferrer' target={'_blank'} href={'https://renzo.in/contact'}>
                  <FontAwesomeIcon icon={faDotCircle} className={'mr-1'} size={'xs'}/>
                  Renzo Support
                </a>
              </li>
              <li className="list-inline-item">
                <a className={'small text-secondary'} rel='noopener noreferrer' target={'_blank'} href={'https://renzo.in/policies/privacy'}>
                  <FontAwesomeIcon icon={faDotCircle} className={'mr-1'} size={'xs'}/>
                  Renzo Privacy
                </a>
              </li>
              <li className="list-inline-item">
                <a className={'small text-secondary'} rel='noopener noreferrer' target={'_blank'} href={'https://renzo.in/policies/terms'}>
                  <FontAwesomeIcon icon={faDotCircle} className={'mr-1'} size={'xs'}/>
                  Renzo T & C
                </a>
              </li>
              <li className="list-inline-item">
                <span className={'small text-secondary'}>
                  <FontAwesomeIcon
                    onClick={()=>this.setState({showBuildInformation: true})}
                    icon={faDotCircle} size={'xs'}
                  />
                </span>
              </li>
            </ul>
          </div>
          {this.props.poweredBy &&
           <div className={'col-12 col-md-3'}>
             <div className="text-md-right text-center brand">
               {this.props.poweredBy && <p className={'small mb-0 text-secondary'}>Powered by</p>}
               <img alt={'Renzo'} src={renzoLogo}/>
             </div>
           </div>
          }
        </div>
        <BuildInformation
          show={this.state.showBuildInformation}
          onHide={()=>this.setState({showBuildInformation: false})}
        />
      </div>
    );
  };

  constructor(props) {
    super(props);

    this.state = {
      showBuildInformation: false,
    };
  }
}


/* Export Component ==================================================================== */
export default Footer;
