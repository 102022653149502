/**
 * Alerts - Status/Success/Error Messages
 *
 <Alerts
 error={'Error hey'}
 success={'Hello Success'}
 status={'Something\'s happening...'}
 />
 *
 */
import React, {Component} from 'react';
import PropTypes from 'prop-types';


/* Component ==================================================================== */
class Alerts extends Component {
  static componentName = 'Alerts';

  static propTypes = {
    status: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    success: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    error: PropTypes.oneOfType([PropTypes.string, PropTypes.array])
  };

  static defaultProps = {
    status: '',
    success: '',
    error: ''
  };

  processMessage = (message) => {
    if (typeof message === 'object') {
      const messageItems = message.map((messageItem, i) =>
        <li key={i} className={'small mb-0'}>{messageItem}</li>
      );
      return (<ul className={'mb-0'}>{messageItems}</ul>);
    } else {
      return (<p className={'mb-0 small'}>{message}</p>);
    }
  };

  render() {
    const {success, status, error} = this.props;
    return (
      <div>
        {success !== '' &&
         <div className={'alert alert-success'}>
           {this.processMessage(success)}
         </div>
        }

        {status !== '' &&
         <div className={'alert alert-info'}>
           {this.processMessage(status)}
         </div>
        }

        {error !== '' &&
         <div className={'alert alert-danger'}>
           {this.processMessage(error)}
         </div>
        }
      </div>
    );
  };
}


/* Export Component ==================================================================== */
export default Alerts;
