import React from 'react';
import { Route, Switch } from 'react-router-dom';

import Launch from './Home';
import Payments from './Payments';
import NotFound from './NotFound';
import Failed from './Failed';


const PaymentsRoute = () => (
  <div>
    <Switch>
      <Route path="/" exact component={Launch}/>
      <Route path="/fail" exact component={Failed}/>
      <Route path="/:PaymentId" exact component={Payments}/>
      <Route exact component={NotFound}/>
    </Switch>
  </div>
);

export default PaymentsRoute;