/**
 *
 * Payments
 * Payments information
 */
import React, {Component} from 'react';
import {connect} from 'react-redux';
import PropTypes from 'prop-types';
import Helmet from 'react-helmet';
import _ from 'lodash';
// Consts and Libs
import AppAPI from '../lib/api';
import AppUtil from '../lib/util';
import {Constants} from '../constants';
// Components
import NotFound from './NotFound';
import Footer from '../components/ui/Footer';
import Loading from '../components/general/Loading';
import PaymentGateways from './components/PaymentGateways';
import ClickToView from '../components/general/ClickToView';
// Font-Awesome Icons
import {faDotCircle} from '@fortawesome/free-solid-svg-icons';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';

// Actions
import * as PaymentAction from '../redux/payment/actions';

/* Redux ==================================================================== */
// What data from the store shall we send to the component?
const mapStateToProps = (state) => ({
  payment: state.payment.payment,
});

// Any actions to map to the component?
const mapDispatchToProps = {
  setPayment: PaymentAction.setPayment,
  checkPaymentStatus: PaymentAction.checkPaymentStatus,
};


/* Component ==================================================================== */
class Payments extends Component {
  static componentName = 'Payments';

  static propTypes = {
    match: PropTypes.object.isRequired,
  };

  componentDidMount = () => {
    this.fetchInitData();
  };

  fetchInitData = () => {
    this.props.setPayment(this.props.match.params.PaymentId)
      .then(() => {
        this.setState({loading: false});
      })
      .catch((err) => {
        const error = AppAPI.handleError(err);
        this.setState({
          loading: false,
          error,
        });
      });
  }

  render = () => {

    const {loading, error} = this.state;
    const {payment} = this.props;
    const {property} = payment;

    if (loading) return <Loading/>;
    if (_.isEmpty(payment)) return <NotFound full={true} error={error}/>;

    return (
      <div className="index screen-container site-wrapper site-wrapper-absolute">
        <Helmet>
          <title>
            {(payment.payment_status_display && `[${payment.payment_status_display}] `)}
            Payment for {(payment.ref_no && `[${payment.ref_no}] `)}
          </title>
        </Helmet>

        <div className="container h-100">
          <div className="row h-100 justify-content-center align-items-center">
            <div className={'col-sm-12 col-md-10 col-lg-8 col-xl-8 mx-auto'}>
              <div className={' border rounded p-3 my-3'}>
                <h5>Payment Request {!_.isEmpty(property) && `form ${property.name}`}</h5>
                <hr/>
                <div className="row payment-block">
                  <div className="col-sm-12 col-md-6 col-lg-5 overflow-hidden align-self-center mb-md-0 mb-3">
                    <p className="text-uppercase small text-secondary pb-0 mb-0">Payment Reference</p>
                    <p>{payment.ref_no}</p>

                    {(payment.payment_status !== Constants.PAYMENT_STATUS.CANCELLED) &&
                    <React.Fragment>
                      <p className="text-uppercase small text-secondary pb-0 mb-0">Payment Amount</p>
                      <p><strong>{payment.currency} {payment.total}</strong></p>

                      {payment.due_date &&
                      <React.Fragment>
                        <p className="text-uppercase small text-secondary pb-0 mb-0">Expire on</p>
                        <p>{AppUtil.formatDateTime(payment.due_date)}</p>
                      </React.Fragment>
                      }

                      {!_.isEmpty(payment.attributes_public) &&
                      <React.Fragment>
                        {payment.attributes_public.description &&
                        <React.Fragment>
                          <p className="text-uppercase small text-secondary pb-0 mb-0">Description</p>
                          <p className="small pb-0 mb-0">{payment.attributes_public.description}</p>
                        </React.Fragment>
                        }
                      </React.Fragment>
                      }
                    </React.Fragment>
                    }
                  </div>

                  <div className="col-sm-12 col-md-6 col-lg-7 overflow-hidden align-self-center ">
                    <PaymentGateways
                      payment={payment}
                      setPayment={this.props.setPayment}
                      checkPaymentStatus={this.props.checkPaymentStatus}
                    />
                  </div>
                </div>
                {!_.isEmpty(property) &&
                <React.Fragment>
                  <hr/>
                  <div className="row">
                    <div className={'col-sm-12 col-md-12 col-lg-12'}>
                      <div className="media">
                        {(!_.isEmpty(property.logo) && property.logo.logo) &&
                        <div className={'footer-brand mr-3'}>
                          <img src={property.logo.logo} alt={'Logo'}/>
                        </div>
                        }
                        <div className="media-body">
                          <h6 className="mt-0 mb-0">{property.name}</h6>
                          <p className={'small text-muted mb-0'}>
                            {property.full_address}
                          </p>
                          <p className={'mb-0 text-muted small'}>
                            <ClickToView
                              hiddenText={'Support Contact ( Click to Show)'}
                              buttonClass={'a underline'}
                              content={`${property.support_email && `e-mail : ${property.support_email}`} ${property.support_phone && ` | phone : ${property.support_phone}`} `}
                            />
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                  {(!_.isEmpty(property.property_content) && !_.isEmpty(property.property_content.policy_links)) &&
                  <div className="row">
                    <div className={'col-12'}>
                      <ul className="list-inline text-center mb-1">
                        {property.property_content.policy_links.map((data, i) => (
                          <li className="list-inline-item" key={i}>
                            {data.link &&
                            <a className={'small'} href={data.link} rel='noopener noreferrer' target={'_blank'}>
                              <FontAwesomeIcon icon={faDotCircle} className={'mr-1'} size={'xs'}/>
                              {data.title}
                            </a>
                            }
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                  }
                </React.Fragment>
                }
              </div>

              {!_.isEmpty(property) &&
              <div className={'row mb-3'}>
                <div className={'col-12 text-center'}>
                  <p className={'small text-secondary mb-0'}>
                    All payments are handled individually by our payment gateway(PG) partners
                    and {payment.property.name}.
                  </p>
                  <p className={'small text-secondary mb-0'}>
                    Renzo is not involved in any payment processing. All our PG partners are certified payment
                    processors.</p>
                </div>
              </div>
              }
              <Footer poweredBy={true}/>
            </div>
          </div>
        </div>
      </div>
    );
  };


  constructor(props) {
    super(props);

    this.state = {
      loading: true,
      resultMsg: {
        status: '',
        success: '',
        error: '',
      },
    };
  }
}


export default connect(mapStateToProps, mapDispatchToProps)(Payments);